import React, { useCallback, useId } from 'react';
import { t } from "i18next";
import { ToastIntent, Toaster, makeStyles, useToastController } from '@fluentui/react-components';
import { getToastMessageComponent } from '../../common/components/ToastMessage';
import SpinnerComponent from '../../common/components/controls/spinner';
import { getfileNameFromUrl, uploadFileAndGetToken } from './fileActionUtils';
import { getEnvironment360URL } from '../../services/configs/appConfig';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    spinnerStyles:
     {
        paddingTop: "30vh"
     },
  });
const ArchiveFile: React.FC = () => {
    const loadingText = t("Archiving files to 360...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const toasterId = useId();
    const { dispatchToast ,dismissToast } = useToastController(toasterId);
    const navigate = useNavigate();
    const showToast = (message:string ,toastType:ToastIntent)=>{
        dispatchToast(
            getToastMessageComponent(message,archiveTheFile),
            { timeout:-1, intent: toastType }
          );
    };
    const openOfficeDialogToArchive = (resData: string)=>{
        Office.context.ui.displayDialogAsync(window.location.origin + "/dialogrediect.html?docno="
            + encodeURIComponent(resData) + "&public360Url=" + encodeURIComponent(getEnvironment360URL()), {height: 70, width: 52, promptBeforeOpen: false},
            function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                setShowSpinner(false);
                showToast(t("Failed to open dialog: ") as string + asyncResult.error.message  ,"error");
            } else {
                const dialog = asyncResult.value;

                // Add event handler for when the dialog is closed
                dialog.addEventHandler(Office.EventType.DialogEventReceived,  ()=> {
                    onDialogClosed(resData);
                });
            }
            }
        );
    };
    function onDialogClosed(docRecno: string) {
        setShowSpinner(false);
        navigate(`/archivehome?docRecno=${encodeURIComponent(docRecno)}`);
    }
   const archiveTheFile  = ()=> {
    dismissToast(toasterId);
    setShowSpinner(true);
    const prereqData =   getPrerequisiteData();
    let checkWindowClosed: NodeJS.Timeout;
    try {
        if(prereqData?.fileName){
            uploadFileAndGetToken(prereqData.fileName, (resData : string | null) => {
                if(resData){
                    openOfficeDialogToArchive(resData);
                }else{
                    showToast(t("Recno not generated"),"error");
                    setShowSpinner(false);
                }

            },(err:string)=>{
                showToast(err,"error");
                setShowSpinner(false);
            });
        }


    }
    catch (exception) {
        setShowSpinner(false);
        showToast(exception as string,"error");
    }

    return () => clearInterval(checkWindowClosed);
   };

   const getPrerequisiteData = useCallback( () => {

    if (Office.context.document?.url) {
        const docUrl = Office.context.document.url;
        const fileName = getfileNameFromUrl(docUrl);

        const prereqData = {
            fileId: null, // need to find it when doing checkout flow
            serverName: '',
            fileName: fileName
        };
               return prereqData;
            }else{
                return null;
            }

}, []);

    React.useEffect(() => {

        archiveTheFile();
    },[]);


    return (
        <>
            <Toaster toasterId={toasterId} />
            <SpinnerComponent showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' spinnerStyle={useStyles().spinnerStyles}/>
        </>
    );
};

export default ArchiveFile;
